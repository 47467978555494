import { Tooltip, ToggleInput } from '@cycle-app/ui';

import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useProduct, useProductAddOn } from 'src/hooks';
import { useUpdateProductAutopilot } from 'src/hooks/useUpdateProductAutopilot';
import { setLimitationsModal } from 'src/reactives';
import { FrontEndIntegration } from 'src/types/integrations.types';

import { Row, RowHeader, Cell } from './FeedbackAutopilot.styles';

export const CycleSourceItem = () => {
  const type = FrontEndIntegration.CYCLE;
  const { product } = useProduct();
  const updateAutoPilot = useUpdateProductAutopilot();
  const addOn = useProductAddOn('UNLIMITED_AI_QUERIES');

  const checkAddOn = () => {
    if (addOn.isEnabled) return true;
    setLimitationsModal({
      action: 'USE_ADD_ON',
      brand: 'UNLIMITED_AI_QUERIES',
    });
    return false;
  };

  const data = integrationsDataMap[type];

  if (!product || !data) return null;

  return (
    <Row>
      <RowHeader>
        <Tooltip
          content="Cycle in-app"
          placement="left"
        >
          {data.icon}
        </Tooltip>
      </RowHeader>

      <Cell>
        <ToggleInput
          id={`${type}-find-insights`}
          checked={product.extractInsights}
          onChange={e => {
            if (!checkAddOn()) return;
            // Unchecking extractInsights automatically unchecks linkToRoadmap and markFeedbackAsProcessed
            updateAutoPilot({
              extractInsights: e.target.checked,
              linkToRoadmap: false,
              markFeedbackProcessed: false,
            });
          }}
          variant="secondary"
        />
      </Cell>

      <Cell>
        <ToggleInput
          id={`${type}-link-roadmaps`}
          disabled={!product.extractInsights}
          checked={product.linkToRoadmap}
          onChange={e => {
            if (!checkAddOn() || !product.extractInsights) return;
            // linkToRoadmap can only be checked if extractInsights is checked
            updateAutoPilot({
              extractInsights: true,
              linkToRoadmap: e.target.checked,
            });
          }}
          variant="secondary"
        />
      </Cell>

      <Cell>
        <ToggleInput
          id={`${type}-create-parent`}
          disabled={!product.extractInsights}
          checked={product.createParent}
          onChange={e => {
            if (!checkAddOn() || !product.extractInsights) return;
            // linkToRoadmap can only be checked if extractInsights is checked
            updateAutoPilot({
              extractInsights: true,
              createParent: e.target.checked,
            });
          }}
          variant="secondary"
        />
      </Cell>

      <Cell>
        <ToggleInput
          id={`${type}-mark-processed`}
          disabled={!product.extractInsights}
          checked={product.markFeedbackAsProcessed}
          onChange={e => {
            if (!checkAddOn() || !product.extractInsights) return;
            // markFeedbackAsProcessed can only be checked if extractInsights is checked
            updateAutoPilot({
              extractInsights: true,
              markFeedbackProcessed: e.target.checked,
            });
          }}
          variant="secondary"
        />
      </Cell>
    </Row>
  );
};
