import { typo, Skeleton } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { AddOns } from 'src/components/AddOns/AddOns';
import { FeaturesList } from 'src/components/FeaturesList';
import { MakersAvatar } from 'src/components/MakersAvatar';

export const Header = styled.header`
  margin-bottom: 88px;

  h1 {
    font-size: 26px;
    font-weight: 600;
  }
`;

export const StyledMakers = styled(MakersAvatar)`
  gap: 15px;
  justify-content: flex-end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 780px;
  margin: 0 auto;
  margin-bottom: 30vh;
`;

const sectionCss = css`
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 2px 5px ${p => p.theme.colors.billing.section.shadow};
  border: 1px solid ${p => p.theme.colors.billing.section.border};
  background-color: ${p => p.theme.colors.billing.section.bg};
`;

export const Section = styled.div`
  ${sectionCss}
`;

export const SectionTitle = styled.h2`
  ${typo.headerSmall};
`;

export const Bold = styled.span`
  ${typo.body500}
`;

export const PriceToPaySkeleton = styled(Skeleton)`
  height: 8px;
  display: inline-block;
  width: 40px;
  margin-left: 5px;
`;

export const StyledAddons = styled(AddOns)`
  ${sectionCss}
`;

export const StyledFeaturesList = styled(FeaturesList)`
  margin-top: 20px;
`;
