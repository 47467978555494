import {
  Skeleton, boxShadowZ2, typo, Button, ActionButton, ButtonCss, Input,
} from '@cycle-app/ui';
import { DuplicateIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Header = styled.header`
  h1 {
    font-size: 26px;
    font-weight: 600;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 780px;
  margin: 60px auto 30vh;
`;

export const Section = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: ${p => p.theme.colors.settings.section.bg};
  border: 1px solid ${p => p.theme.colors.settings.section.border};
  border-radius: 12px;
  ${boxShadowZ2};

  footer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const SectionTitle = styled.h2`
  ${typo.headerSmall}
  margin-bottom: 4px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

export const Info = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.settings.section.info};
`;

const StyledButton = styled(Button)`
  height: 32px;
  padding: 0 12px;
  ${typo.body500}
`;

export { StyledButton as Button };

const Link = styled.a`
  ${ButtonCss}
`;

export const DocLink = styled(Link).attrs({
  $variant: 'secondary',
  $useCycleColor: true,
  $size: 'M',
})`
  height: 32px;
  padding: 0 12px;
  ${typo.body500}
`;

export const DocLinkAlt = styled(Link).attrs({
  $variant: 'nospace',
  $size: 'M',
})`
  ${typo.body400}
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const ItemCard = styled.div`
  height: 44px;
  border-radius: 6px;
  padding: 0 12px;
  background: ${p => p.theme.colors.settings.listItem.bg};
  display: flex;
  align-items: center;
  gap: 12px;
  ${p => p.onClick && `
    cursor: pointer;
    :hover {
      background: ${p.theme.colors.settings.listItem.bgHover};
    }
  `};
`;

export const ItemCardSkeleton = styled(Skeleton)`
  height: 40px;
  border-radius: 6px;
`;

export const ItemTitle = styled.p`
  flex: 1;
  ${typo.body500}
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  height: 24px;
  width: 24px;
`;

export const StyledModal = styled(PortalModal)`
  width: 450px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ModalTitle = styled.h2`
  ${typo.headerSmall};
  font-weight: 500;
`;

export const KeyInput = styled(Input)`
  input, input:hover, input:focus {
    cursor: text;
    color: ${p => p.theme.colors.text.primary};
    background: transparent;
    border: 1px solid ${p => p.theme.colors.border.primary} !important;
  }
  button:focus-visible {
    outline: none;
  }
`;

export const CopyButton = styled(DuplicateIcon)`
  cursor: pointer;
  color: ${p => p.theme.colors.text.disabled};
  :hover {
    color: ${p => p.theme.colors.text.hover};  
  }
`;

export const AppIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.text.white};
  background: linear-gradient(180deg,
    rgba(54,61,116,1) 0%,
    rgba(37,86,227,1) 50%,
    rgba(159,159,234,1) 100%);
`;
