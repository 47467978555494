import { CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import {
  SelectOption,
  ActionButton,
  CheckboxInput,
  CustomPropertyInputText,
  Warning,
  OnSelectOptionsChangeMetaData,
  Flex,
  PropertyInputType,
} from '@cycle-app/ui';
import { BackArrowIcon, StatusIcon, ReleaseIcon } from '@cycle-app/ui/icons';
import { nodeToArray, isUrl } from '@cycle-app/utilities';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isPresent } from 'ts-is-present';
import { useDebouncedCallback } from 'use-debounce';

import { AttributeOptionsManager } from 'src/components/AttributeOptionsManager';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useAddNewAttributeToDoc } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useAttributes } from 'src/hooks/api/useAttributes';
import { useDirtyAssigneeDefinition, useDirtyParentDefinition } from 'src/hooks/api/useDirtyAssigneeDefinition';
import { useMe } from 'src/hooks/api/useMe';
import { useCompatibility } from 'src/hooks/useCompatibility';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { getDocType, useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { customAttributeTypeData, ATTRIBUTE_ICON_MAPPING, getAttributeName } from 'src/utils/attributes.util';
import { getDocFromCache } from 'src/utils/cache.utils';
import { getIsAtLeastOneInsightFromDocs } from 'src/utils/doc.util';
import { isInsight } from 'src/utils/docType.util';
import { getPageIdFromPathname } from 'src/utils/routing.utils';
import { getUserOptions } from 'src/utils/users.util';

import { openCreateReleaseBulk } from '../../reactives/releases.reactive';
import { DocSearch } from '../DocSearch/DocSearch';
import { ReleasesPanel } from '../ReleasesPanel';
import {
  Container,
  CurrentProperty,
  CurrentPropertyName,
  StyledSelectPanel,
  InputTextContainer,
} from './EditProperty.styles';
import { EditStatus } from './EditStatus';

export type OnValueSelectedParams = {
  attributeDefinition: CustomAttributeDefinitionFragment;
  propertyValue?: string;
  isValueRemoved?: boolean;
  notCompatible?: boolean;
};

export interface OnAddNewAttributeToDocArgs {
  attributeDefinition: CustomAttributeDefinitionFragment;
  textValue: string;
}

interface Props {
  possibleAttributes: CustomAttributeDefinitionFragment[];
  goBack?: VoidFunction;
  onValueUpdated?: (p: OnValueSelectedParams) => void;
  onAddNewAttributeToDoc?: (args: OnAddNewAttributeToDocArgs) => Promise<void> | void;
  onAssigneeUpdated?: (userId: string | null, notCompatible: boolean) => void;
  onParentUpdated?: (parentDocId: string | undefined) => void;
  bulk?: boolean;
  docId?: string;
  hide?: VoidFunction;
  commonDoctypeParents?: Array<string>;
  hideIncompatibleValues?: boolean;
  compatibleStatusIds?: string[];
  onReleaseUpdate?: (releaseId: string) => void;
  hideReleaseId?: string;
}

// @todo refacto to use common code with PropertyDropdownValue

export const EditProperty = ({
  possibleAttributes,
  goBack,
  onValueUpdated,
  onAddNewAttributeToDoc,
  onAssigneeUpdated,
  onParentUpdated,
  onReleaseUpdate,
  bulk,
  docId: docIdProps,
  hide,
  commonDoctypeParents,
  hideIncompatibleValues,
  compatibleStatusIds,
  hideReleaseId,
}: Props): JSX.Element => {
  const { docTypes } = useGetDocTypes();
  const { isEnabled: isStatusEnabled } = useFeatureFlag('status');
  const [propertyId, setPropertyId] = useState<string | null>(null);
  const [checked, setChecked] = useState(false);
  const { addNewAttributeToDoc } = useAddNewAttributeToDoc();
  const { selected } = useGetSelection();
  const attributes = useAttributes();
  const usersForAssignee = useBoardConfig(ctx => ctx.usersForAssignee);
  const assigneeIsRequired = useBoardConfig(ctx => ctx.assigneeIsRequired);
  const { me } = useMe();
  const assigneeAttribute = useDirtyAssigneeDefinition();
  const parentAttribute = useDirtyParentDefinition();

  const {
    getCompatibleOptions, isPropertyRequiredToBeVisible, canPropertyCreateOption, shouldDisplayWarning,
  } = useCompatibility();

  const currentProperty = useMemo(() => {
    if (propertyId === 'status' || propertyId === 'release') return undefined;

    const customAttribute = attributes.find(attribute => attribute.id === propertyId);
    if (customAttribute) return customAttribute;
    // TODO: Improve this later once we can get the AssigneeDefinition easily from the API

    if (propertyId === 'parent' && parentAttribute?.__typename) {
      return {
        ...parentAttribute,
        name: getAttributeName(parentAttribute),
      };
    }

    if (propertyId && assigneeAttribute?.__typename) {
      return {
        ...assigneeAttribute,
        name: getAttributeName(assigneeAttribute),
        values: usersForAssignee.map(user => ({
          value: user.id,
          label: user.firstName,
        })),
      };
    }
    return undefined;
  }, [assigneeAttribute, attributes, propertyId, usersForAssignee, parentAttribute]);

  const getDocs = useCallback(() => {
    return (bulk ? selected : [docIdProps])
      .map((docId) => (docId ? getDocFromCache(docId) : undefined))
      .filter(isPresent);
  }, [bulk, docIdProps, selected]);

  const propertiesOptions: SelectOption[] = useMemo(() => {
    const attrs: SelectOption[] = possibleAttributes.map(attribute => ({
      value: attribute?.id ?? '',
      label: attribute?.name ?? '',
      icon: attribute?.__typename ? ATTRIBUTE_ICON_MAPPING[attribute.__typename] : undefined,
    }));
    const isAnInsight = getIsAtLeastOneInsightFromDocs(getDocs(), docTypes);

    if (
      parentAttribute?.__typename &&
      onParentUpdated &&
      bulk
    ) {
      attrs.unshift({
        value: parentAttribute?.id,
        label: getAttributeName(parentAttribute),
        icon: ATTRIBUTE_ICON_MAPPING[parentAttribute.__typename],
      });
    }

    if (
      onAssigneeUpdated &&
      assigneeAttribute?.__typename &&
      !isAnInsight
    ) {
      attrs.push({
        value: assigneeAttribute?.id,
        label: getAttributeName(assigneeAttribute),
        icon: ATTRIBUTE_ICON_MAPPING[assigneeAttribute.__typename],
      });
    }

    if (
      bulk &&
      isStatusEnabled &&
      /**
       * We didn't show the status is at least one insight is in selection
       * We use `some` so the loop stops as soon as one item match the condition
       */
      !getDocs().some(doc => isInsight(docTypes[doc?.doctype.id ?? '']))
    ) {
      attrs.push(({
        value: 'status',
        label: 'Status',
        icon: <StatusIcon />,
      }));
    }

    if (onReleaseUpdate) {
      attrs.push({
        value: 'release',
        label: 'Release',
        icon: <ReleaseIcon />,
      });
    }

    return attrs;
  }, [
    onReleaseUpdate, possibleAttributes, getDocs, docTypes, parentAttribute,
    onParentUpdated, onAssigneeUpdated, assigneeAttribute, bulk, isStatusEnabled,
  ]);

  const onCreateOption = useCallback(async (textValue: string) => {
    if (currentProperty?.__typename !== 'AttributeSingleSelectDefinition') return;
    const doc = docIdProps ? getDocFromCache(docIdProps) : null;
    if (doc) {
      await addNewAttributeToDoc({
        doc,
        attributeDefinition: currentProperty,
        textValue,
      });
    }
    await onAddNewAttributeToDoc?.({
      attributeDefinition: currentProperty,
      textValue,
    });
  }, [addNewAttributeToDoc, onAddNewAttributeToDoc, currentProperty, docIdProps]);

  useEffect(() => {
    setChecked(isInitialChecked());
  }, [currentProperty]);

  const onMultiSelectOptionsChange = useCallback((newOptions: SelectOption[], metaData: OnSelectOptionsChangeMetaData) => {
    if (currentProperty?.__typename !== 'AttributeMultiSelectDefinition') return;
    const isPropertyRequired = isPropertyRequiredToBeVisible(currentProperty);
    const filteredOptions = getCompatibleOptions(currentProperty);

    const addedOption = metaData?.addedOptions?.[0];
    const removedOption = metaData?.removedOptions?.[0];
    const option = addedOption || removedOption;
    if (!option) return;

    const isValueRemoved = !!removedOption;
    const notCompatible = isValueRemoved && newOptions.length === 0
      ? isPropertyRequired
      : !filteredOptions.map(a => a.node.id).includes(option.value);

    onValueUpdated?.({
      attributeDefinition: currentProperty,
      propertyValue: option.value,
      isValueRemoved,
      notCompatible,
    });
  }, [currentProperty, getCompatibleOptions, isPropertyRequiredToBeVisible, onValueUpdated]);

  const debouncedOnValueUpdated = useDebouncedCallback((data: OnValueSelectedParams) => {
    onValueUpdated?.(data);
    setPropertyId(null);
  }, INPUT_ONCHANGE_DEBOUNCE);
  const containerHasPadding = propertyId ? ![
    'AttributeSingleSelectDefinition',
    'AttributeMultiSelectDefinition',
  ].includes(currentProperty?.__typename || '') : true;

  return (
    <Container $hasPadding={containerHasPadding}>
      {!currentProperty?.__typename && (propertyId !== 'status' && propertyId !== 'release') ? (
        <StyledSelectPanel
          title={(
            <>
              {goBack && (
                <ActionButton onClick={goBack}>
                  <BackArrowIcon />
                </ActionButton>
              )}
              <span>Select property</span>
            </>
          )}
          onOptionChange={(option) => {
            setPropertyId(option.value);
          }}
          options={propertiesOptions}
        />
      ) : (
        <CurrentProperty>
          <CurrentPropertyName>
            <ActionButton onClick={() => setPropertyId(null)}>
              <BackArrowIcon />
            </ActionButton>
            {propertyId === 'status' && 'Status'}
            {propertyId === 'release' && 'Release'}
            {currentProperty?.__typename && (
              <>
                {ATTRIBUTE_ICON_MAPPING[currentProperty.__typename]}
                {currentProperty.name}
              </>
            )}
          </CurrentPropertyName>

          <div>
            {renderPropertyInput()}
          </div>
        </CurrentProperty>
      )}
    </Container>
  );

  function renderPropertyInput() {
    if (hide && propertyId === 'release' && onReleaseUpdate) {
      return (
        <ReleasesPanel
          hide={hide}
          releaseId={hideReleaseId}
          onChange={onReleaseUpdate}
          {...bulk && {
            onCreateRelease: openCreateReleaseBulk,
          }}
        />
      );
    }

    if (propertyId === 'status') {
      const docIds = bulk ? selected : [docIdProps];
      const docTypeIds = docIds
        .map(docId => (docId ? getDocFromCache(docId)?.doctype.id : undefined))
        .filter(isPresent);
      return (
        <EditStatus
          onChange={hide}
          docTypeIds={docTypeIds}
          compatibleStatusIds={compatibleStatusIds}
        />
      );
    }

    if (!currentProperty?.__typename) {
      return null;
    }

    if (currentProperty.__typename === 'AssigneeDefinition') {
      let docTypeName: string | undefined;
      if (!bulk && docIdProps) {
        const doc = getDocFromCache(docIdProps);
        if (doc) {
          const docType = docTypes[doc.doctype.id];
          if (docType) docTypeName = docType.name;
        }
      }
      const showWarnings = !docIdProps || !getDocFromCache(docIdProps)?.isDraft;
      const isFeedbackSection = getPageIdFromPathname(window.location.pathname) === PageId.InboxView;
      const hideClearValue = isFeedbackSection || (hideIncompatibleValues && assigneeIsRequired);
      const users = usersForAssignee.filter(user => (!hideIncompatibleValues || user._compatibleWithBoardConfig));
      return (
        <StyledSelectPanel
          onOptionChange={({ value: userId }) => {
            const notCompatible = !usersForAssignee.find(u => u.id === userId)?._compatibleWithBoardConfig;
            onAssigneeUpdated?.(userId, notCompatible);
            setPropertyId(null);
            hide?.();
          }}
          options={getUserOptions(users, docTypeName, me, showWarnings)}
          {...!hideClearValue && (
            {
              onClearValue: () => {
                onAssigneeUpdated?.(null, assigneeIsRequired);
                setPropertyId(null);
                hide?.();
              },
            }
          )}
          warningOnNoneValue={assigneeIsRequired}
          docTypeName={docTypeName}
        />
      );
    }

    if (currentProperty.__typename === 'AttributeSingleSelectDefinition') {
      let docTypeName: string | undefined;
      if (!bulk && docIdProps) {
        const doc = getDocFromCache(docIdProps);
        if (doc) {
          const docType = docTypes[doc.doctype.id];
          if (docType) docTypeName = docType.name;
        }
      }
      const isPropertyRequired = isPropertyRequiredToBeVisible(currentProperty);
      const filteredOptions = getCompatibleOptions(currentProperty);
      const hideClearValue = hideIncompatibleValues && isPropertyRequired;
      const canCreateOption = canPropertyCreateOption(currentProperty);

      return (
        <AttributeOptionsManager
          options={nodeToArray(currentProperty.values)
            .filter(value => (!hideIncompatibleValues || filteredOptions.map(a => a.node.id).includes(value.id)))
            .map((value) => ({
              label: value.value,
              value: value.id,
              end: docTypeName && !filteredOptions.map(a => a.node.id).includes(value.id) ? (
                <Warning tooltip={`The ${docTypeName ? docTypeName.toLowerCase() : 'doc'} will leave the view if you choose this value`} />
              ) : undefined,
            }))}
          attributeDefinition={currentProperty}
          onSelect={({ value }) => {
            onValueUpdated?.({
              attributeDefinition: currentProperty,
              propertyValue: value,
              notCompatible: !filteredOptions.map(a => a.node.id).includes(value),
            });

            setPropertyId(null);
            hide?.();
          }}
          showWarningOnNoneValue={isPropertyRequired}
          onCreate={canCreateOption ? async (newOption) => {
            if (typeof newOption === 'string') {
              await onCreateOption(newOption);
            }
          } : undefined}
          shouldCreateValueInComponent={false}
          onClearValue={!hideClearValue ? () => {
            onValueUpdated?.({
              attributeDefinition: currentProperty,
              isValueRemoved: true,
              notCompatible: isPropertyRequired,
            });

            setPropertyId(null);
            hide?.();
          } : undefined}
        />
      );
    }

    if (currentProperty.__typename === 'AttributeMultiSelectDefinition') {
      if (!docIdProps) return null;
      const isPropertyRequired = isPropertyRequiredToBeVisible(currentProperty);
      const filteredOptions = getCompatibleOptions(currentProperty);

      const doc = getDocFromCache(docIdProps);
      const docTypeName = docTypes[doc?.doctype.id ?? '']?.name;
      const docAttribute = nodeToArray(doc?.attributes).find(a => a.definition.id === currentProperty.id);
      const selectedValues: string[] = docAttribute?.__typename === 'DocAttributeMultiSelect'
        ? docAttribute.selectValues?.map(v => v.id) ?? []
        : [];
      const options = nodeToArray(currentProperty.values)
        .filter(value => (!hideIncompatibleValues || filteredOptions.map(a => a.node.id).includes(value.id)))
        .map((value) => ({
          label: value.value,
          value: value.id,
          selected: selectedValues.includes(value.id),
          end: !filteredOptions.map(a => a.node.id).includes(value.id) ? (
            <Warning tooltip={`The ${docTypeName?.toLowerCase()} will leave the view if you choose this value`} />
          ) : undefined,
        }));

      return (
        <AttributeOptionsManager
          isMulti
          options={options}
          attributeDefinition={currentProperty}
          onSelect={(newOption) => {
            const allOption = newOption.selected ? options : [...options, newOption];
            const meta = newOption.selected ? { removedOptions: [newOption] } : { addedOptions: [newOption] };
            onMultiSelectOptionsChange(allOption, meta);
          }}
          onCreate={newOption => {
            if (typeof newOption !== 'string') {
              onMultiSelectOptionsChange([{
                value: newOption.id,
                label: '',
              }], { addedOptions: [] });
            }
          }}
          showWarningOnNoneValue={isPropertyRequired}
        />
      );
    }

    if (currentProperty.__typename === 'AttributeCheckboxDefinition') {
      const notCompatible = shouldDisplayWarning(currentProperty);
      return (
        <Flex $gap={8}>
          <CheckboxInput
            id="edit-property-checkbox"
            value={currentProperty.name}
            hideLabel
            checked={checked}
            onChange={() => {
              const nextChecked = !checked;
              setChecked(nextChecked);
              onValueUpdated?.({
                attributeDefinition: currentProperty,
                propertyValue: nextChecked ? 'checked' : 'uncheck',
                isValueRemoved: !nextChecked,
                notCompatible,
              });
            }}
          />
          {notCompatible && (
            <Warning
              tooltip="This will hide the docs from current view"
            />
          )}
        </Flex>
      );
    }

    if (currentProperty.__typename === 'ParentDefinition' && commonDoctypeParents) {
      return (
        <DocSearch
          possibleDoctypes={commonDoctypeParents.map(id => getDocType(id)).filter(isPresent)}
          searchVariables={{
            doctypeIds: commonDoctypeParents,
          }}
          onAdd={parentDocId => onParentUpdated?.(parentDocId)}
          onRemove={() => onParentUpdated?.(undefined)}
          showNoneOption
        />
      );
    }

    if (currentProperty.__typename !== 'ParentDefinition') {
      const { input: inputType } = customAttributeTypeData[currentProperty.__typename];

      if (inputType === 'email' ||
        inputType === 'phone' ||
        inputType === 'number' ||
        inputType === 'date' ||
        inputType === 'url' ||
        inputType === 'text') {
        return (
          <InputTextContainer>
            <CustomPropertyInputText
              type={inputType}
              variant="dropdown"
              values={['']}
              validate={getValidate(inputType)}
              onInputChange={(e) => {
                let { value } = e.target;
                if (inputType === 'url' && !isUrl(value)) {
                  value = `https://${value}`;
                }
                debouncedOnValueUpdated({
                  // @ts-expect-error Typescript doesn't understand it can't get AssigneeDefinition here
                  attributeDefinition: currentProperty,
                  propertyValue: value,
                });
              }}
            />
          </InputTextContainer>
        );
      }
    }

    return null;
  }

  function isInitialChecked() {
    if (currentProperty?.__typename !== 'AttributeCheckboxDefinition') {
      return false;
    }

    const docs = getDocs();

    const checkedValues = docs.map(doc => {
      const docAttribute = nodeToArray(doc?.attributes).find(attribute => attribute.definition.id === currentProperty?.id);
      if (docAttribute?.__typename !== 'DocAttributeCheckbox') {
        return false;
      }
      return !!docAttribute?.checkboxValue?.value;
    });

    return !checkedValues.includes(false);
  }
};

const getValidate = (inputType: PropertyInputType) => {
  if (inputType === 'url') {
    return (value: string) => (value && !isUrl(value, { strict: false }) ? 'URL format is incorrect' : null);
  }
  return undefined;
};
