import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { nodeToArray, getDocSlug } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { useMemo, useState, FC } from 'react';

import { InsightDocSearchDropdown } from 'src/components/DocSearchDropdown/InsightDocSearchDropdown';
import { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import { useDocTypeInsight, useDocV2, useOptimizedBooleanState, useDocInsightCreate } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useUrl } from 'src/hooks/useUrl';
import { getInheritedAttributeFromDoc, getSubmittableAttributeValues } from 'src/utils/attributes.util';
import { insightName } from 'src/utils/doc.util';
import { getDocTypeNames } from 'src/utils/docType.util';
import { getDefaultInsightTitle } from 'src/utils/insight.utils';

import { InsightCreateModal } from '../../InsightCreateModal';
import { StyledDocParent, EditIconContainer, StyledPenFilledIcon, StyledLink } from './InsightDropdown.styles';

interface Props {
  blockId?: string;
  dropdownProps?: Partial<Omit<DropdownLayerProps, 'content'>>;
  defaultContent?: string;
  feedback: DocBaseFragment;
  docTarget?: {
    doc?: {
      id: string;
      parent?: {
        id: string;
        title: string;
        doctype: {
          id: string;
        };
      } | null;
    } | null;
  } | null;
  isInitialVisible?: boolean;
  isVisible?: boolean;
  onHide?: VoidFunction;
  onInsightCreating?: VoidFunction;
  onInsightCreated?: VoidFunction;
  defaultCover?: string;
}

export const InsightDropdown: FC<React.PropsWithChildren<Props>> = ({
  blockId,
  dropdownProps,
  defaultContent,
  docTarget,
  feedback,
  isInitialVisible,
  isVisible,
  onHide,
  onInsightCreating,
  onInsightCreated,
  defaultCover,
}) => {
  const [isDocSearchVisible, {
    setTrueCallback: showDocSearch, setFalseCallback: hideDocSearch,
  }] = useOptimizedBooleanState(!!isInitialVisible);
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const [withoutParent, setWithoutParent] = useState(false);
  const { insight } = useDocTypeInsight();
  const {
    doc: parentDoc, isLoading: isParentLoading,
  } = useDocV2(insightDocParentId);
  const changeDocParent = useChangeDocParent();
  const getUrl = useUrl();
  const possibleDoctypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);
  const { create: createInsight } = useDocInsightCreate();

  const onOptionSelected = async (parentId?: string) => {
    const {
      common, specific,
    } = getInheritedAttributeFromDoc({
      originAttributes: nodeToArray(feedback?.attributes),
      targetAttributesDefinitions: nodeToArray(insight?.attributeDefinitions),
    });
    if (specific.length === 0) {
      const titleValue = getDefaultInsightTitle({
        customerName: feedback.customer?.name,
        docTitle: feedback.title,
      });
      await createInsight(
        {
          assignee: feedback.assignee?.id || '',
          contentJSON: '',
          customerId: feedback.customer?.id || '',
          docLinkContent: defaultContent || titleValue,
          docLinkSourceId: feedback.id,
          doctypeId: insight?.id || '',
          parentId,
          properties: getSubmittableAttributeValues(common),
          title: titleValue,
          blockId,
          sourceId: feedback.source?.id || '',
        },
        /**
         * If it's a new doc we pass the parent to set the common attributes
         */
        { parentDoc },
      );
      hideDocSearch();
    } else {
      if (parentId) {
        setInsightDocParentId(parentId);
      } else {
        setWithoutParent(true);
      }
      hideDocSearch();
    }
  };

  return feedback ? (
    <InsightCreateModal
      isOpen={!!parentDoc?.id || isParentLoading || withoutParent}
      hide={() => {
        if (!isDocSearchVisible) {
          onHide?.();
          return;
        }
        setInsightDocParentId('');
        setWithoutParent(false);
      }}
      parentDoc={parentDoc}
      feedbackDoc={feedback}
      dropdownProps={dropdownProps}
      defaultContent={defaultContent}
      defaultCover={defaultCover}
      blockId={blockId}
      onInsightCreating={onInsightCreating}
      onInsightCreated={onInsightCreated}
      hideTitleField
      isLoading={isParentLoading}
      withoutParent={withoutParent}
    >
      <InsightDocSearchDropdown
        showNoneOption
        childDoctypeId={insight?.id}
        possibleDoctypes={possibleDoctypes}
        dropdownProps={dropdownProps}
        hideSearchDropdown={() => {
          if (!insightDocParentId) {
            onHide?.();
            return;
          }
          hideDocSearch();
        }}
        isSearchDropdownVisible={isDocSearchVisible}
        onRemove={onOptionSelected}
        onAdd={async (docId) => {
          let parentId = docId;

          if (docTarget?.doc) {
            const result = await changeDocParent({
              docId: docTarget.doc.id,
              parentId: docId,
            });
            if (result?.data?.changeDocParent?.id) {
              parentId = result.data.changeDocParent.id;
            }
            onHide?.();
            return;
          }

          await onOptionSelected(parentId);
        }}
        placeholder={getDocTypeNames(possibleDoctypes, {
          prefix: `Link ${insightName()} to`,
          suffix: '',
        })}
        semanticSearch={defaultContent}
      >
        {isVisible && docTarget?.doc?.parent && (
          <motion.div
            initial={{
              opacity: 0,
              translateY: -6,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <StyledLink to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(docTarget.doc.parent) })}>
              <StyledDocParent
                doc={docTarget.doc.parent}
                showTitle
                showIcon
                size="XS"
                tooltipProps={{
                  content: docTarget.doc.parent.title,
                }}
              >
                <EditIconContainer
                  onClick={(e) => {
                    e.preventDefault();
                    showDocSearch();
                  }}
                >
                  <Tooltip content="Change parent" placement="bottom">
                    <StyledPenFilledIcon />
                  </Tooltip>
                </EditIconContainer>
              </StyledDocParent>
            </StyledLink>
          </motion.div>
        )}
      </InsightDocSearchDropdown>
    </InsightCreateModal>
  ) : null;
};
