import { AnimatePresence } from 'framer-motion';
import { Route, Switch, Redirect } from 'react-router-dom';

import { OfflineState } from 'src/components/OfflineState';
import { CustomersView, CompaniesView } from 'src/components/Views';
import { PageId, routing } from 'src/constants/routing.constant';
import { useIsOffline, useLocation, useRouteAccess } from 'src/hooks';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { usePageId } from 'src/hooks/usePageId';

import { Container, ScrollableContent, ContentPage } from './Settings.styles';
import { SettingsAi } from './SettingsAI';
import { SettingsAPI } from './SettingsAPI';
import { SettingsAttributes } from './SettingsAttributes/SettingsAttributes';
import { SettingsBilling } from './SettingsBilling/SettingsBilling';
import { SettingsFeedback, SettingsInsights } from './SettingsDocType/SettingsDocType';
import { SettingsIntegrations } from './SettingsIntegrations/SettingsIntegrations';
import { SettingsReleases } from './SettingsReleases';
import { SettingsRoadmaps } from './SettingsRoadmaps';
import { SettingsRoadmapsDocType } from './SettingsRoadmapsDocType';
import SettingsSidebar from './SettingsSidebar/SettingsSidebar';
import SettingsUsers from './SettingsUsers/SettingsUsers';
import { SettingsWorkflows } from './SettingsWorkflows';
import SettingsWorkspace from './SettingsWorkspace/SettingsWorkspace';

export const Settings = () => {
  const { isEnabled: isStatusEnabled } = useFeatureFlag('status');
  const { isEnabled: isSettingsApiEnabled } = useFeatureFlag('settings-api');
  const isOffline = useIsOffline();
  const location = useLocation();
  const pageId = usePageId();
  const hasBillingAccess = useRouteAccess(PageId.SettingsBilling);

  return (
    <Container>
      <SettingsSidebar />
      <ScrollableContent className="scrollable">
        {isOffline ? <OfflineState /> : (
          <ContentPage>
            <Switch>
              <Route exact path={routing[PageId.Settings]}>
                <SettingsWorkspace />
              </Route>
              <Route path={routing[PageId.SettingsUsers]}>
                <SettingsUsers />
              </Route>
              <Route path={routing[PageId.SettingsCustomers]} exact>
                <CustomersView />
              </Route>
              <Route path={routing[PageId.SettingsCompanies]} exact>
                <CompaniesView />
              </Route>
              {hasBillingAccess && (
                <Route path={routing[PageId.SettingsBilling]}>
                  <SettingsBilling />
                </Route>
              )}
              <Route path={routing[PageId.SettingsFeedback]}>
                <SettingsFeedback />
              </Route>
              <Route path={routing[PageId.SettingsInsights]}>
                <SettingsInsights />
              </Route>
              <Route path={routing[PageId.SettingsRoadmaps]}>
                <SettingsRoadmaps>
                  <AnimatePresence mode="wait" initial={false}>
                    <Switch location={location} key={pageId}>
                      <Route path={routing[PageId.SettingsRoadmapsDocType]}>
                        <SettingsRoadmapsDocType />
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </SettingsRoadmaps>
              </Route>
              <Route exact path={routing[PageId.SettingsAttributes]}>
                <SettingsAttributes />
              </Route>
              <Route exact path={routing[PageId.SettingsReleases]}>
                <SettingsReleases />
              </Route>
              <Route exact path={routing[PageId.SettingsIntegrations]}>
                <SettingsIntegrations />
              </Route>
              {isSettingsApiEnabled && (
                <Route exact path={routing[PageId.SettingsAPI]}>
                  <SettingsAPI />
                </Route>
              )}
              {isStatusEnabled && (
                <Route exact path={routing[PageId.SettingsWorkflows]}>
                  <SettingsWorkflows />
                </Route>
              )}
              <Route exact path={routing[PageId.SettingsAi]}>
                <SettingsAi />
              </Route>
              <Redirect to={routing[PageId.Settings]} />
            </Switch>
          </ContentPage>
        )}
      </ScrollableContent>
    </Container>
  );
};
