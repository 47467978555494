import { AiState, DocBaseFragment } from '@cycle-app/graphql-codegen';
import { AiIcon, AddIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { useEffect, useRef, useState } from 'react';

import { AiGenetaredInsights } from 'src/components/AiGeneratedInsights';
import { ExtractInsights } from 'src/components/ExtractInsights';
import { InsightCreate } from 'src/components/InsightCreate';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useDocInsights } from 'src/hooks';
import { useGetAiGeneratedInsights } from 'src/reactives/docRightPanel.reactive';
import { insightName } from 'src/utils/doc.util';

import { Container, ListContainer } from './InsightChildrenList.styles';
import { InsightsList } from './InsightsList';
import {
  Buttons, ActionButtons, AddButton, Action, AiButton, AddInsightButton,
} from './InsightsList.styles';
import { copyQuotesToClipboard } from './InsightsList.utils';

type FeedbackInsightListProps = {
  doc: DocBaseFragment;
};

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

export const FeedbackInsightList = ({ doc }: FeedbackInsightListProps) => {
  const {
    insights, isLoading,
  } = useDocInsights(doc.id, {
    aiStates: [AiState.UserValidated, null],
  });
  const loadingBulkDocTargets = useDocPanelContext(ctx => ctx.loadingBulkDocTargets);

  // Add a bottom border to the list if scrollable
  const listRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    const element = listRef.current?.children[0];
    const checkScrollable = () => {
      if (!(element instanceof HTMLDivElement)) return;
      setIsScrollable(element.scrollHeight > element.clientHeight);
    };
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [insights]);

  const { isCollapsed: isFooterCollapsed } = useGetAiGeneratedInsights();

  return (
    <Container>
      <ListContainer ref={listRef} $withBorder={isScrollable || isFooterCollapsed}>
        <InsightsList
          hideSource
          hideAssignee
          hideCustomer
          hideCopy
          insights={insights}
          isLoading={isLoading}
          skeletonsCount={loadingBulkDocTargets.length}
          docTargetsAiCount={doc.docTargetsAiCount}
          modal={insights.length > 0 || loadingBulkDocTargets.length > 0 ? (
            <>
              <ActionButtons>
                <Action
                  size="L"
                  tooltip="Copy all quotes"
                  tooltipPlacement="top"
                  onClick={() => copyQuotesToClipboard(insights)}
                >
                  <DuplicateIcon />
                </Action>
                {!quotesEnabled && (
                  <ExtractInsights
                    button={({
                      isLoading: isButtonLoading, ...props
                    }) => (
                      <Action
                        size="L"
                        tooltip="Find insights"
                        tooltipPlacement="top"
                        {...props}
                      >
                        <AiIcon isAnimated={isButtonLoading} hasGradient size={14} />
                      </Action>
                    )}
                  />
                )}
              </ActionButtons>

              <InsightCreate
                feedbackDoc={doc}
                button={props => (
                  <AddButton
                    onClick={props.onClick}
                    forceFocus={props.isFocus}
                    disabled={props.isDisabled}
                  >
                    <AddIcon size={12} />
                    {`Add ${insightName()}`}
                  </AddButton>
                )}
              />
            </>
          ) : (
            <Buttons>
              <InsightCreate
                feedbackDoc={doc}
                button={props => (
                  <AddInsightButton
                    onClick={props.onClick}
                    forceFocus={props.isFocus}
                    $isFocus={props.isFocus}
                    $isFullWidth
                    disabled={props.isDisabled}
                  >
                    <AddIcon size={12} />
                    {`Add ${insightName()}`}
                  </AddInsightButton>
                )}
              />
              {!quotesEnabled && (
                <ExtractInsights
                  button={({
                    isLoading: isButtonLoading, ...props
                  }) => ((
                    <AiButton size="M" {...props}>
                      <AiIcon isAnimated={isButtonLoading} hasGradient />
                      Find insights
                    </AiButton>
                  ))}
                />
              )}
            </Buttons>
          )}
        />
      </ListContainer>

      {!quotesEnabled && (
        <AiGenetaredInsights
          isCollapsable
          doc={doc}
        />
      )}
    </Container>
  );
};
