import { Button, typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 780px;
  margin: 60px auto 30vh;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  min-width: 650px;

  ${mobile} {
    grid-template-columns: 1fr;
  }
`;

export const LogoUploadLine = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img<{ $size?: number }>`
  height: ${p => p.$size}px;
  width: ${p => p.$size}px;
  margin-right: 8px;
  border-radius: 4px;
  object-fit: cover;
`;

export const LogoUploadButton = styled(Button)`
  flex: 1;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  font-weight: normal;
  color: hsl(var(--cycle));
  border: 1px dashed hsl(var(--cycle));
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SsoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 140px;
`;

export const SsoTitle = styled.div`
  ${typo.body500}
`;

export const SsoDomain = styled.div`
  color: ${p => p.theme.colors.text.secondary};

  svg {
    color: ${p => p.theme.colors.text.disabled};
    vertical-align: text-top;
    margin-left: 6px;
  }
`;
