import { DoctypeType } from '@cycle-app/graphql-codegen';
import memoize from 'fast-memoize';
import { useMemo, FC, useEffect, ReactNode, useState } from 'react';

import { DocPanelActivity } from 'src/components/DocPanelActivity';
import { DocPanelThreads } from 'src/components/DocPanelThreads';
import { FeedbackInsightList, InsightChildrenList } from 'src/components/InsightsList';
import { useFeatureFlag, useLocation } from 'src/hooks';
import useQueryParams from 'src/hooks/useQueryParams';
import { useGetDocPanel, Content, setDocPanel, getDocPanel, getVerifyQuotes } from 'src/reactives/docRightPanel.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { setCommentNavigation } from 'src/reactives/notifications.reactive';
import { useIsMobile, useIsTablet, useIsSmallScreen } from 'src/reactives/responsive.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { insightName } from 'src/utils/doc.util';
import { getInsightChildren } from 'src/utils/docType.util';
import { COMMENT_SEARCH_PARAM } from 'src/utils/notifications.util';

import { CommentsCount } from './CommentsCount';
import {
  Container,
  DisplayedContent,
  StyledAction,
  StyledTabList,
  StyledTab,
} from './DocPanelRightPanel.styles';
import { DocRightPanelEmptyState } from './DocRightPanelEmptyState';
import { InsightsCount } from './InsightsCount';

interface Props {
  doc: FullDocWithPublicId;
}

const DocPanelRightPanel: FC<React.PropsWithChildren<Props>> = ({ doc }) => {
  const { isEnabled: isInlineCommentsEnabled } = useFeatureFlag('inline-comments');
  const docType = useGetDocType(doc.doctype.id);
  const isSmallScreen = useIsSmallScreen();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const location = useLocation();
  const {
    content: rightPanelContent,
    isExpanded: rightPanelExpanded,
  } = useGetDocPanel();
  const insightChildrenDocType = useMemo(() => getInsightChildren(docType), [docType]);

  const onButtonClicked = memoize((content: Content) => () => {
    setDocPanel({
      content,
      isExpanded: !rightPanelExpanded || rightPanelContent !== content,
      autoFocus: true,
    });
  });

  useEffect(() => () => setDocPanel({
    isExpanded: false,
    isHovered: false,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const searchCommentId = useQueryParams().get(COMMENT_SEARCH_PARAM);

  useEffect(() => {
    if (searchCommentId) {
      setCommentNavigation({ sidebarCommentId: searchCommentId });
      setDocPanel({
        isExpanded: true,
        content: Content.Comments,
      });
      return;
    }

    // Opens right panel from doc item actions
    if (location.state?.rightPanel) {
      setDocPanel({
        isExpanded: true,
        content: location.state.rightPanel,
      });
      return;
    }

    if (
      !isSmallScreen && !isTablet &&
      !getVerifyQuotes().isOpen &&
      (doc.doctype.type === DoctypeType.Feedback || insightChildrenDocType)
    ) {
      setDocPanel({
        isExpanded: true,
        content: Content.Insights,
      });
    } else {
      setDocPanel({
        isExpanded: false,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommentId, doc.id, insightChildrenDocType, location, isMobile]);

  const isFeedbackDocType = doc.doctype.type === DoctypeType.Feedback;
  const isExpanded = rightPanelExpanded;
  const showInsights = isFeedbackDocType || insightChildrenDocType;

  const tabs: {
    content: Content;
    label: ReactNode;
    isHidden?: boolean;
    badge?: ReactNode;
  }[] = [
    {
      content: Content.Comments,
      label: 'Comments',
      ...(isExpanded && { badge: <CommentsCount doc={doc} /> }),
    },
    {
      content: Content.Insights,
      label: insightName({
        plural: true,
        uppercase: true,
      }),
      isHidden: !showInsights,
      ...(isExpanded && { badge: <InsightsCount doc={doc} /> }),
    },
    {
      content: Content.Activity,
      label: 'Activity',
    },
  ];

  const [isOpen, setIsOpen] = useState(isExpanded);
  useEffect(() => {
    if (!isExpanded) setIsOpen(false);
  }, [isExpanded]);

  return (
    <Container
      $collapsed={!isExpanded}
      index={tabs.findIndex(tab => tab.content === rightPanelContent)}
      onChange={index => {
        const content = tabs[index]?.content;
        if (!content) return;
        setDocPanel({ content });
      }}
      onKeyDown={() => setDocPanel({ autoFocus: false })}
      onMouseEnter={() => setDocPanel({ isHovered: true })}
      onMouseLeave={() => setDocPanel({ isHovered: false })}
      onTransitionEnd={e => {
        if (e.target === e.currentTarget && getDocPanel().isExpanded) {
          setIsOpen(true);
        }
      }}
    >
      {isOpen && (
        <>
          {!isMobile && (
            <StyledTabList>
              {tabs.map((tab) => (
                <StyledTab key={tab.content} $isHidden={!!tab.isHidden}>
                  {!tab.isHidden && (
                    <StyledAction
                      {...rightPanelContent === tab.content && { className: 'force-focus' }}
                      role="button"
                      tabIndex={0}
                      onClick={onButtonClicked(tab.content)}
                    >
                      {tab.label}
                      {tab.badge}
                    </StyledAction>
                  )}
                </StyledTab>
              ))}
            </StyledTabList>
          )}

          <DisplayedContent
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {rightPanelContent === Content.Comments && (isMobile || !isInlineCommentsEnabled) && (
              <DocRightPanelEmptyState doc={doc} />
            )}

            {rightPanelContent === Content.Comments && !isMobile && isInlineCommentsEnabled && (
              <DocPanelThreads
                docId={doc.id}
                docTitle={doc.title.trim() || 'Untitled'}
              />
            )}

            {rightPanelContent === Content.Activity && (
              <DocPanelActivity />
            )}

            {rightPanelContent === Content.Insights && (
              (insightChildrenDocType
                ? <InsightChildrenList doc={doc} doctypeId={insightChildrenDocType.id} />
                : <FeedbackInsightList doc={doc} />
              )
            )}
          </DisplayedContent>
        </>
      )}
    </Container>
  );
};

export default DocPanelRightPanel;
