import { DocAttribute, DoctypeType, DocTargetResultFragment } from '@cycle-app/graphql-codegen';
import { CompanyLogo, ActionButton } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { ReactNode } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocSource } from 'src/components/DocSource';
import { DocTagProperties } from 'src/components/DocTagProperties';
import { DOC_PREVIEW_DELAY, useDocPreview } from 'src/hooks/doc/useDocPreview';
import { useInsightCardEditQuoteProps } from 'src/hooks/insight/useInsightCardEditQuoteProps';
import { useGetPermission } from 'src/reactives';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { useGetDocTypeName } from 'src/reactives/docTypes.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { Layer } from 'src/types/layers.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { DocLinear } from '../DocLinear';
import {
  PropertiesContainer,
  OptionContainer,
  CustomerContainer,
  CustomerLabel,
  CustomerName,
} from '../InsightsList/InsightsList.styles';
import { Actions, StyledInsightCard } from './SuggestionsModal.styles';

type Props = {
  doc: DocTargetResultFragment;
  parent?: ReactNode;
  showSource?: boolean;
  showAssignee?: boolean;
  showCustomer?: boolean;
  enablePreview?: boolean;
};

export const SuggestionInsightCard = ({
  doc,
  parent,
  showSource = false,
  showAssignee = false,
  showCustomer = false,
  enablePreview = false,
}: Props) => {
  const {
    contextEl,
    contextUpdateEl,
    startEdit,
  } = useInsightCardEditQuoteProps({
    docTarget: {
      content: doc.docSource?.content,
      doc,
    },
  });
  const { canUpdateInsight } = useGetPermission();

  const insightLinkDocTypeName = useGetDocTypeName(doc?.doctype.id);

  const {
    showDocPreview, hideDocPreview,
  } = useDocPreview();

  const quoteContent = doc.docSource?.content || doc.title;

  const hasProperties = !!nodeToArray(doc.attributes).length;

  return (
    <StyledInsightCard
      key={doc.id}
      hasBorder
      title={doc.title}
      context={contextEl}
      contextText={quoteContent}
      hasSmallMaxHeight
      {...enablePreview && {
        onContextMouseEnter: () => {
          showDocPreview(doc.id, DOC_PREVIEW_DELAY);
        },
        onContextMouseLeave: () => {
          hideDocPreview();
        },
      }}
      onMouseEnter={() => {
        setDocItem({ hoverDocId: doc.id });
      }}
      onMouseLeave={() => {
        setDocItem({ hoverDocId: null });
      }}
      onContextCopied={context => copyToClipboard({
        text: context,
        notification: 'Text copied to clipboard!',
      })}
      parentSlot={parent}
      properties={(
        <PropertiesContainer>
          {showSource && (
            <DocSource
              doctypeId={doc.doctype.id}
              docId={doc.id}
              source={doc.source}
              color="greyAlt"
            />
          )}
          {hasProperties && (
            <DocTagProperties
              properties={nodeToArray(doc.attributes) as DocAttribute[]}
            />
          )}
        </PropertiesContainer>
      )}
      options={(
        <OptionContainer>
          <Actions>
            {doc.parent?.id && (
              <DocLinear
                docId={doc.parent.id}
                automationId={doc.parent.automationId}
                automationUrl={doc.parent.automationUrl}
              />
            )}

            <ActionButton
              tooltip="Edit quote"
              tooltipPlacement="top"
              onClick={e => {
                e.stopPropagation();
                if (canUpdateInsight) {
                  startEdit();
                } else {
                  setLimitationsModal({ action: 'INSIGHT_UPDATE' });
                }
              }}
            >
              <PenFilledIcon size={14} />
            </ActionButton>
          </Actions>

          {showAssignee && !!doc.assignee?.id && insightLinkDocTypeName && (
            <DocAssignee
              assignee={doc.assignee}
              showLabel={false}
              tooltipPlacement="top"
              isRemovable={false}
              docId={doc.id}
              context="doc-item"
              docTypeName={insightLinkDocTypeName}
              docTypeType={DoctypeType.Insight}
              layer={Layer.DropdownModal}
            />
          )}
        </OptionContainer>
        )}
      contextFooter={showCustomer && doc.customer && (
        <CustomerContainer>
          <DocCompanyCustomer
            size="S"
            doc={doc}
            isDisabled
            showCompanyName
          >
            <CustomerLabel>
              <CompanyLogo company={doc.customer.company} size="XS" />
              <CustomerName>
                {doc.customer.displayName}
              </CustomerName>
              {doc.customer.company?.name && (
                <>
                  <span>·</span>
                  <span>{doc.customer.company.name}</span>
                </>
              )}
            </CustomerLabel>
          </DocCompanyCustomer>
        </CustomerContainer>
      )}
      contextUpdate={contextUpdateEl}
    />
  );
};
