import { typo, card } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import SavingLabel from 'src/components/SavingLabel/SavingLabel';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  h1 {
    font-size: 26px;
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const HeaderSide = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 780px;
  margin: 60px auto 30vh;
`;

export const FakeSectionContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 132px;
  border-radius: 4px;
  color: ${p => p.theme.colors.text.secondary};

  > ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 20px 20px;

      span {
        color: ${p => p.theme.colors.text.primary};
        font-size: 16px;
        filter: blur(8px);
        opacity: 0.5;
      }
    }
  }

  > p {
    z-index: 3;
  }
`;

export const SavingLabelStyled = styled(SavingLabel)`
  color: ${p => p.theme.colors.text.disabled};
  ${typo.body400}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  ${card}

  footer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const InfoIcon = styled(InfoIconOutline).attrs({ size: 16 })`
  color: ${p => p.theme.colors.text.disabled};
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const Bold = styled.div`
  ${typo.body500}
`;
