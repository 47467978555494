import { SelectLine } from '@cycle-app/ui';
import { DownIcon, AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { isPresent } from 'ts-is-present';
import { useDebouncedCallback } from 'use-debounce';

import { Hint, NoComment } from 'src/app/Main/Board/DocPanel/DocPanelRightPanel/DocPanelRightPanel.styles';
import { DocCommentNew } from 'src/components/DocCommentNew';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useLocation } from 'src/hooks';
import { useDocThreads } from 'src/hooks/api/queries/useDocThreads';
import { useGetDoc } from 'src/reactives';
import { showAllThreads, showOpenThreads, showResolvedThreads, useThreadsPanel } from 'src/reactives/comments.reactive';
import { setCommentNavigation, useGetCommentNavigation } from 'src/reactives/notifications.reactive';

import {
  Container, Header, HeaderButton, Label, DropdownContent, Content, EmptyStateContainer, AddCommentContainer,
} from './DocPanelThreads.styles';
import { ThreadItem } from './ThreadItem';

export const DocPanelThreads = ({
  docId, docTitle,
}: {
  docId: string;
  docTitle: string;
}) => {
  // Show open threads on close
  useEffect(() => () => showOpenThreads(), []);

  return (
    <Container>
      <ThreadsHeader docId={docId}>
        <ResolvedDropdown />
      </ThreadsHeader>
      <Content>
        <ThreadList docId={docId} docTitle={docTitle} />
      </Content>
    </Container>
  );
};

const ResolvedDropdown = () => {
  const { section } = useThreadsPanel();
  return (
    <ToggleDropdown
      placement="bottom-start"
      button={props => (
        <HeaderButton {...props}>
          {section === 'open' && 'Open threads'}
          {section === 'closed' && 'Closed threads'}
          {section === 'all' && 'All threads'}
          <DownIcon size={12} />
        </HeaderButton>
      )}
      content={props => (
        <DropdownContent>
          <SelectLine
            label={<Label>Open threads</Label>}
            isDisabled={section === 'open'}
            onClick={() => {
              props.hide();
              showOpenThreads();
            }}
          />
          <SelectLine
            label={<Label>Closed threads</Label>}
            isDisabled={section === 'closed'}
            onClick={() => {
              props.hide();
              showResolvedThreads();
            }}
          />
          <SelectLine
            label={<Label>All threads</Label>}
            isDisabled={section === 'all'}
            onClick={() => {
              props.hide();
              showAllThreads();
            }}
          />
        </DropdownContent>
      )}
    />
  );
};

const ThreadsHeader = ({
  docId, children,
}: {
  docId: string;
  children: ReactNode;
}) => {
  const [isAddCommentOpen, setIsAddCommentOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { section } = useThreadsPanel();

  const {
    threads, loading,
  } = useDocThreads(docId, { section });

  const isMainThreadEmpty = section !== 'closed' && !loading &&
    threads.length > 0 &&
    !threads.some(thread => thread.comments?.edges[0]?.node.blockId === null);

  return (
    <div ref={ref}>
      {isAddCommentOpen && isMainThreadEmpty ? (
        <AddComment
          docId={docId}
          hide={() => setIsAddCommentOpen(false)}
        />
      ) : (
        <Header>
          {children}
          {isMainThreadEmpty && (
            <HeaderButton
              iconStart={<AddIcon size={10} />}
              onClick={() => setIsAddCommentOpen(true)}
            >
              Add comment
            </HeaderButton>
          )}
        </Header>
      )}
    </div>
  );
};

const AddComment = ({
  docId, hide,
}: {
  docId: string;
  hide: VoidFunction;
}) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        e.stopPropagation();
        hide();
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [hide]);

  return (
    <AddCommentContainer>
      <DocCommentNew
        docId={docId}
        blockId={null}
        isReply={false}
        autoFocus
        placeholder="Write a new comment"
        onSend={hide}
      />
    </AddCommentContainer>
  );
};

// Show the resolved threads if the comment id from notifications is in a resolved thread
const useHandleSearchCommentId = (docId: string) => {
  const { sidebarCommentId } = useGetCommentNavigation();

  const { threads } = useDocThreads(docId, {
    section: 'all',
    skip: !sidebarCommentId,
  });

  const comments = threads.flatMap(
    thread => nodeToArray(thread.comments).map(comment => ({
      id: comment.id,
      threadId: thread.id,
    })),
  ).filter(isPresent) ?? [];

  const comment = comments.find(c => c.id === sidebarCommentId);

  const editor = useDocPanelContext(ctx => ctx.editor);
  const location = useLocation();
  useEffect(() => {
    if (comment && !!editor) {
      showAllThreads();
      setCommentNavigation({
        sidebarCommentId: undefined,
        threadId: comment?.threadId,
        editorCommentId: sidebarCommentId,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads, editor, location]);
};

const ThreadList = ({
  docId, docTitle,
}: {
  docId: string;
  docTitle: string;
}) => {
  useHandleSearchCommentId(docId);
  const { section } = useThreadsPanel();
  const {
    threads, resolvedIds,
  } = useDocThreads(docId, {
    section,
  });

  const [marksHtmlById, setMarksHtmlById] = useState<Record<string, string>>();

  const {
    yDoc, isEditorSyncing,
  } = useGetDoc();

  const onDocUpdated = useDebouncedCallback(() => setMarksHtmlById(getMarksHtml()), INPUT_ONCHANGE_DEBOUNCE);

  // Get the marks html when the doc is updated
  useEffect(() => {
    if (!isEditorSyncing) {
      onDocUpdated();
      yDoc?.on('update', onDocUpdated);
    }
  }, [yDoc, isEditorSyncing, onDocUpdated]);

  const setThreadsCount = useDocPanelContext(ctx => ctx.setThreadsCount);

  const items = threads
    .map(thread => {
      const firstComment = thread.comments?.edges.at(-1)?.node;
      const blockId = firstComment?.blockId;
      return {
        thread,
        firstComment,
        blockId,
      };
    })
    .filter(item => {
      if (!item.firstComment || item.blockId === undefined) return false;
      // Filter out inaccessibles threads (= with deleted marks) from sections 'open' and 'closed'
      if (section === 'open' && item.blockId && !marksHtmlById?.[item.blockId]) return false;
      if (section === 'closed' && item.blockId && !marksHtmlById?.[item.blockId]) return false;
      return true;
    });

  useEffect(() => {
    setThreadsCount(items.length);
  }, [items.length, setThreadsCount]);

  if (!threads.length && section !== 'closed') return <EmptyState docId={docId} />;

  return (
    <>
      {items.map(item => {
        const firstComment = item.thread.comments?.edges.at(-1)?.node;
        const blockId = firstComment?.blockId;
        if (!item.firstComment || item.blockId === undefined) return null;
        let markHtml: string | null | undefined;
        if (blockId === null) markHtml = docTitle;
        if (blockId && marksHtmlById) markHtml = marksHtmlById[blockId] ?? null;
        return (
          <ThreadItem
            key={item.thread.id}
            threadId={item.thread.id}
            docId={docId}
            blockId={item.blockId}
            // eslint-disable-next-line no-nested-ternary
            markHtml={markHtml}
            replyCount={item.thread.commentsCount - 1}
            comment={item.firstComment}
            isResolved={resolvedIds.includes(item.thread.id)}
          />
        );
      })}
    </>
  );
};

const EmptyState = ({ docId }: { docId: string }) => {
  const theme = useTheme();
  return (
    <EmptyStateContainer>
      <NoComment>
        <img
          src={`/images/empty-comments-${theme.isDark ? 'dark' : 'light'}.png`}
          alt="empty-comments"
        />
        <p>No comment yet</p>
      </NoComment>

      <DocCommentNew
        docId={docId}
        blockId={null}
        isReply={false}
        autoFocus
      />

      <Hint>use @ to mention someone</Hint>
    </EmptyStateContainer>
  );
};

const getMarksHtml = () => {
  const marks = [...document.querySelectorAll('[data-mark-type="comment"]')];

  // There can be multiple marks with the same block id
  const marksById = marks.reduce<Record<string, Element[]>>((acc, el) => {
    const id = el.getAttribute('data-mark-id');
    if (!id) return acc;
    return {
      ...acc,
      [id]: [...acc[id] ?? [], el],
    };
  }, {});

  // Get all html content between each mark
  const entries = Object.entries(marksById).map(([id, elements]) => {
    if (elements.length === 1) return [id, getNodeHtml(elements[0])];
    let html = '';
    for (const [index, element] of elements.entries()) {
      let node: ChildNode | null = element;
      while (node && node !== elements[index + 1]) {
        const nodeHtml = getNodeHtml(node);
        if (nodeHtml) html += ` ${nodeHtml}`;
        node = node.nextSibling;
      }
    }
    return [id, html];
  });

  return Object.fromEntries(entries);
};

const getNodeHtml = (node?: ChildNode): string => {
  if (!(node instanceof HTMLElement)) return '';
  if (node.tagName === 'BR') return '';
  if (node.classList.contains('node-mention-docs')) return '';

  if (node.getAttribute('data-mark-type') === 'comment') {
    return [...node.childNodes]
      .filter(n => !(n instanceof HTMLElement) || !n.classList.contains('collaboration-cursor__caret'))
      .map(n => n.textContent)
      .join('');
  }

  return node.outerHTML;
};
