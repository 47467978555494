import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Tooltip, ToggleInput } from '@cycle-app/ui';

import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useProductAddOn } from 'src/hooks';
import { useUpdateFeedbackAutoPilot } from 'src/hooks/api/mutations/useUpdateFeedbackAutoPilot';
import { setLimitationsModal } from 'src/reactives';
import { FrontEndIntegration, Integration } from 'src/types/integrations.types';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { Row, RowHeader, Cell } from './FeedbackAutopilot.styles';

type SourceItemProps = {
  type: Integration;
  integrationId?: string;
  providerId?: string;
  extractInsights?: boolean;
  linkToRoadmap?: boolean;
  markFeedbackAsProcessed?: boolean;
  createParent?: boolean;
};

export const SourceItem = ({
  type,
  integrationId,
  providerId,
  extractInsights = false,
  linkToRoadmap = false,
  markFeedbackAsProcessed = false,
  createParent = false,
}: SourceItemProps) => {
  const productId = useWorkspaceContext(context => context.productId);
  const updateAutopilot = useUpdateFeedbackAutoPilot(type);
  const addOn = useProductAddOn('UNLIMITED_AI_QUERIES');

  const checkAddOn = () => {
    if (addOn.isEnabled) return true;
    setLimitationsModal({
      action: 'USE_ADD_ON',
      brand: 'UNLIMITED_AI_QUERIES',
    });
    return false;
  };

  const data = integrationsDataMap[type];

  if ((!data || !updateAutopilot) && type !== FrontEndIntegration.CYCLE_API && type !== FrontEndIntegration.CYCLE) {
    return null;
  }

  const availableSources = [
    IntegrationType.Slack,
    IntegrationType.Zapier,
    IntegrationType.Extension,
    IntegrationType.Meeting,
    IntegrationType.Intercom,
    IntegrationType.Hubspot,
    IntegrationType.Gong,
    IntegrationType.Zendesk,
    IntegrationType.Salesforce,
  ];

  const isComingSoon = !availableSources.find(x => x === type);
  const isDisabled = !integrationId || !providerId || isComingSoon;

  return (
    <Row>
      <RowHeader>
        <Tooltip
          content={data.label}
          placement="left"
        >
          {data.icon}
        </Tooltip>
      </RowHeader>

      <Cell>
        <Tooltip
          content="Coming soon"
          placement="top"
          disabled={!isComingSoon}
        >
          <ToggleInput
            id={`${type}-find-insights`}
            disabled={isDisabled}
            checked={extractInsights}
            onChange={e => {
              if (!checkAddOn() || isDisabled || !updateAutopilot) return;
              // Unchecking extractInsights automatically unchecks linkToRoadmap and markFeedbackAsProcessed
              updateAutopilot(
                {
                  productId,
                  integrationId,
                  extractInsights: e.target.checked,
                  linkToRoadmap: false,
                  createParent: false,
                  markFeedbackProcessed: false,
                },
                {
                  id: providerId,
                  extractInsights: e.target.checked,
                  linkToRoadmap: false,
                  markFeedbackAsProcessed: false,
                  createParent: false,
                },
              );
            }}
            variant="secondary"
          />
        </Tooltip>
      </Cell>

      <Cell>
        <Tooltip
          content="Coming soon"
          placement="top"
          disabled={!isComingSoon}
        >
          <ToggleInput
            id={`${type}-link-roadmaps`}
            disabled={isDisabled || !extractInsights}
            checked={linkToRoadmap}
            onChange={e => {
              if (!checkAddOn() || isDisabled || !extractInsights || !updateAutopilot) return;
              // linkToRoadmap can only be checked if extractInsights is checked
              updateAutopilot({
                productId,
                integrationId,
                extractInsights: true,
                linkToRoadmap: e.target.checked,
              }, {
                id: providerId,
                extractInsights: true,
                linkToRoadmap: e.target.checked,
                createParent,
                markFeedbackAsProcessed,
              });
            }}
            variant="secondary"
          />
        </Tooltip>
      </Cell>

      <Cell>
        <Tooltip
          content="Coming soon"
          placement="top"
          disabled={!isComingSoon}
        >
          <ToggleInput
            id={`${type}-create-parent`}
            disabled={isDisabled || !extractInsights}
            checked={createParent}
            onChange={e => {
              if (!checkAddOn() || isDisabled || !extractInsights || !updateAutopilot) return;
              // linkToRoadmap can only be checked if extractInsights is checked
              updateAutopilot({
                productId,
                integrationId,
                extractInsights: true,
                createParent: e.target.checked,
              }, {
                id: providerId,
                extractInsights: true,
                linkToRoadmap,
                createParent: e.target.checked,
                markFeedbackAsProcessed,
              });
            }}
            variant="secondary"
          />
        </Tooltip>
      </Cell>

      <Cell>
        <Tooltip
          content="Coming soon"
          placement="top"
          disabled={!isComingSoon}
        >
          <ToggleInput
            id={`${type}-mark-processed`}
            disabled={isDisabled || !extractInsights}
            checked={markFeedbackAsProcessed}
            onChange={e => {
              if (!checkAddOn() || isDisabled || !extractInsights || !updateAutopilot) return;
              // markFeedbackAsProcessed can only be checked if extractInsights is checked
              updateAutopilot({
                productId,
                integrationId,
                extractInsights: true,
                markFeedbackProcessed: e.target.checked,
              }, {
                id: providerId,
                extractInsights: true,
                linkToRoadmap,
                createParent,
                markFeedbackAsProcessed: e.target.checked,
              });
            }}
            variant="secondary"
          />
        </Tooltip>
      </Cell>
    </Row>
  );
};
