import { AiState, DocAttribute, DocTargetResultFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { CompanyLogo, InsightCardProps, Emoji, Flex } from '@cycle-app/ui';
import { ParentAltIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { ReactNode } from 'react';

import { AiStateTag } from 'src/components/AiStateTag';
import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocSource } from 'src/components/DocSource';
import { DocTagProperties } from 'src/components/DocTagProperties';
import { CommentEditor } from 'src/components/Editor/Editors/CommentEditor';
import { CustomerLabel, CustomerName, PropertiesContainer } from 'src/components/InsightsList/InsightsList.styles';
import { useGetDocType } from 'src/reactives/docTypes.reactive';

import { ContextFooter, StyledInsightCardReadOnly, Parent, ParentTitle } from './InsightCardReadOnly.styles';

type Props = Omit<InsightCardProps, 'context' | 'title'> & {
  doc: DocTargetResultFragment | DocTargetResultFragment;
  showAttributes?: boolean;
  context?: ReactNode;
  showSource?: boolean;
  showAssignee?: boolean;
  showCustomer?: boolean;
};

export const InsightCardReadOnly = ({
  doc,
  showAttributes = false,
  context,
  showSource = false,
  showAssignee = false,
  showCustomer = false,
  ...props
}: Props) => {
  const parentDocType = useGetDocType(doc.parent?.doctype?.id);
  return (
    <StyledInsightCardReadOnly
      hasBorder
      hideCopy
      title={doc.docSource?.doc?.title ?? ''}
      context={context ?? (
        <CommentEditor
          isReadOnly
          content={doc.docSource?.content || doc.title || ''}
        />
      )}
      parentSlot={doc.parent && (
        <Parent>
          <ParentAltIcon size={10} />
          <Emoji size={12} emoji={parentDocType?.emoji} />
          {doc.parent.aiState === AiState.AiCreated && (
            <AiStateTag
              docId={doc.parent.id}
              docTypeId={doc.parent.doctype.id}
              aiState={doc.parent.aiState}
              isDisabled
            />
          )}
          <ParentTitle>{doc.parent.title}</ParentTitle>
        </Parent>
      )}
      properties={(
        <PropertiesContainer>
          {showSource && (
            <DocSource
              doctypeId={doc.doctype.id}
              docId={doc.id}
              source={doc.source}
              color="greyAlt"
            />
          )}
          {showAttributes && (
            <DocTagProperties properties={nodeToArray(doc.attributes) as DocAttribute[]} />
          )}
        </PropertiesContainer>
      )}
      options={(
        <Flex $gap={8}>
          <AiStateTag aiState={doc.aiState} />
          {showAssignee && doc.assignee?.id && (
            <DocAssignee
              assignee={doc.assignee}
              showLabel={false}
              tooltipPlacement="top"
              isDisabled
              isRemovable={false}
              docId={doc.id}
              context="doc-item"
              docTypeName="Insight"
              docTypeType={DoctypeType.Insight}
            />
          )}
        </Flex>
      )}
      contextFooter={showCustomer && doc.customer && (
        <ContextFooter>
          <DocCompanyCustomer
            size="S"
            doc={doc}
            isDisabled
            showCompanyName
          >
            <CustomerLabel>
              <CompanyLogo company={doc.customer.company} size="XS" />
              <CustomerName>
                {doc.customer.displayName}
              </CustomerName>
              {doc.customer.company?.name && (
                <>
                  <span>·</span>
                  <span>{doc.customer.company.name}</span>
                </>
              )}
            </CustomerLabel>
          </DocCompanyCustomer>
        </ContextFooter>
      )}
      {...props}
    />
  );
};
