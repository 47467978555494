import {
  ActionButton, Tag, typo, truncate, ShyScrollbarCss, EmojiPicker, Input,
  SelectPanel, Button,
} from '@cycle-app/ui';
import { Line } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine.styles';
import { List } from '@cycle-app/ui/components/Selects/SelectPanel/SelectPanel.styles';
import { LinearEstimationIcon, LinearIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import { DropdownLayer, ToggleDropdown } from 'src/components/DropdownLayer';
import { PortalModal } from 'src/components/PortalModal';
import { linkCss } from 'src/styles/Editor/Editor.styles';

const dropdownCss = css`
  width: 600px;
  max-width: calc(100vw - 16px);
  padding: 12px;
  ${List} {
    margin: 0 -12px;
  }
  ${Line} {
    padding: 0 12px;
  }
`;

export const StyledToggleDropdown = styled(ToggleDropdown)`
  ${dropdownCss}
`;

export const StyledDropdownLayer = styled(DropdownLayer)`
  ${dropdownCss}
`;

export const StyledTag = styled(Tag)`
  ${p => !p.onClickLink && `
    color: ${p.theme.colors.text.secondary};
    path {
      fill: ${p.theme.colors.text.secondary};
    }
  `}
`;

export const Header = styled.div`
  margin: -4px -12px 4px;
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const CreateButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'M',
})`
  padding: 2px 8px;
  margin-bottom: 7px;
`;

export const Infos = styled.div`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
  user-select: text;
`;

export const Skeletons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  > div {
    height: 20px;
  }
`;

export const IssueIcon = styled(LinearIcon)<{ $isInactive?: boolean }>`
  flex: none;
  ${p => p.$isInactive && `
    color: currentcolor !important;
  `};
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${ShyScrollbarCss}
`;

export const PreviewHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 24px;
`;
export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  > * {
    flex: none;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
`;

export const ProjectName = styled.p`
  ${truncate}
  ${typo.body500}
  flex: 1;
`;

export const ProjectLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${p => p.theme.colors.text.secondary};
  svg {
    color: ${p => p.theme.colors.text.disabled};
  }
  :hover {
    &, svg {
      color: ${p => p.theme.colors.text.primary};
    }
  }
`;

export const ProjectIssue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  overflow: hidden;
`;

export const IssueTitle = styled.p`
  ${truncate}
  flex: 1;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
`;

export const Description = styled.div<{ $isExpanded: boolean }>`
  ${linkCss}
  ${p => !p.$isExpanded && `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  `}
`;

export const IssueDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StyledModal = styled(PortalModal)`
  width: 450px;
  max-width: calc(100vw - 16px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const ModalTitle = styled.p`
  ${typo.headerLight}
`;

export const Form = styled.form`
  display: contents;
  > footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
`;

export const FieldSet = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  > div {
    overflow: hidden;
  }
`;

export const FieldName = styled.span`
  ${truncate}
`;

export const FieldValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;

export const MoreValues = styled.span`
  margin-left: 2px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const EstimationIcon = styled(LinearEstimationIcon)`
  width: 14px;
  height: 14px;
  flex: none;
  color: ${p => p.theme.colors.text.secondary};
`;

export const PrioritySvg = styled.svg`
  width: 14px;
  height: 14px;
  flex: none;
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledEmojiPicker = styled(EmojiPicker)`
  width: 40px;
  height: 40px;
`;

export const ProjectTitleInput = styled(Input)`
  flex: 1;
`;

export const ResultPanel = styled(SelectPanel)<{ $isFetching: boolean }>`
  ${p => p.$isFetching && `
    ${List} {
    opacity: 0.5;
  `}
`;
